.msg-doc_relative{
  position: relative;
}
.msg-doc_loader{
  min-width: 100px;
  min-height: 60px;
}
.msg-doc_main{
  position: relative;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding:9px 10px;
  background-color: rgba(191, 191, 191, 0.2);
  border-radius: 10px;
}
.msg-doc_content{
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 400px;
}
.msg-doc_name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 550;
  font-family: Nunito;
  font-size: 14px;
  line-height: 24px; 
}
.msg-doc_pointer{
  cursor: pointer;
}
.msg-doc_color{
  color: black;
  font-weight: 500;
}
.msg-doc_size{
  display: flex;
  align-items: center;
  gap: 5px;
  color: #8696A0;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; 
}
.msg-doc_download_button{
  inset: none;
}
.msg-doc-symbol_size{
  font-size: 30px;
}
@media screen and (max-width:900px) {
  .msg-doc_content{
      width: 60%;
  }
}
