@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap);
.App {
  /* font-family: sans-serif; */
  text-align: center;
  font-family: "Montserrat", sans-serif;
  /* font-family: "Source Sans 3", sans-serif; */
}

.rcw-conversation-container {
  box-shadow: 0 5px 40px rgba(0, 0, 0, .16);
}

.rcw-conversation-container .rcw-header {
  /* background-color: #1B9CFC;
  border-radius: 10px 10px 0 0;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 15px 0 25px; */
  display: flex;
  flex-direction: column;
  padding: 14px 14px 14px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #000;
  border-radius: 10px 10px 0 0;
  grid-gap: 2px;
  gap: 2px;
  min-height: 48px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  align-items: flex-start;
  justify-content: center;
}
.rcw-conversation-container .rcw-title {
  /* font-size: 20px;
  margin: 0;
  padding: 15px 0; */
  font-size: 16px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  margin: 0px;
  /* padding: 6px; */
}
.rcw-conversation-container .rcw-close-button {
  /* display: none; */
  width: 48px;
  height: 48px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 150ms ease 0s;
  display: flex;
  position: absolute;
  right: 8px;
  background-color: #000;
  border: 0;
}
.rcw-conversation-container .rcw-close-button .rcw-close {
  width: 12px;
  height: 12px;
}
.rcw-conversation-container .rcw-close-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.rcw-conversation-container .rcw-header span {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1;
  font-weight: normal;
  font-size: 14px;
  align-items: center;
  display: inline-flex;
  width: auto;
  min-width: 16px;
  height: 16px;
}
.rcw-conversation-container .avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
  vertical-align: middle;
}
.rcw-full-screen .rcw-header {
  border-radius: 0;
  flex-shrink: 0;
  position: relative;
}
.rcw-full-screen .rcw-title {
  padding: 0 0 15px;
}
.rcw-full-screen .rcw-close-button {
  background-color: #000;
  border: 0;
  display: block;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 40px;
}
.rcw-full-screen .rcw-close {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 800px) {
  .rcw-conversation-container .rcw-header {
    border-radius: 0;
    flex-shrink: 0;
    position: relative;
  }
  .rcw-conversation-container .rcw-title {
    padding: 0 0 15px;
  }
  .rcw-conversation-container .rcw-close-button {
    background-color: #000;
    border: 0;
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 40px;
  }
  .rcw-conversation-container .rcw-close {
    width: 20px;
    height: 20px;
  }
}
.rcw-message {
  /* margin: 10px; */
  display: flex;
  word-wrap: break-word;
  padding-bottom: 16px;
}
.rcw-client {
  /* color: #ffffff;
  background-color: #1B9CFC;
  margin-left: auto; */
  text-align: left;
  padding: 17px 20px;
  border-radius: 10px;
  position: relative;
  display: inline-block;
  width: auto;
  max-width: 75%;
  color: #fff;
  background-color: #000;
  margin-left: auto;
  /* font-size: 14px;
  line-height: 1.4; */
  /* margin: 0px 0px 10px; */
  /* overflow-wrap: break-word;
  word-break: break-word; */
}
.rcw-message-text {
  font-size: 14px;
    line-height: 1.4;
    /* font-family: "Montserrat", sans-serif; */
    font-weight: 500;
}
.rcw-client,
.rcw-response {
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}
.rcw-response {
  /* background-color: #f4f7f9; */
  padding: 17px 20px;
  border-radius: 10px;
  position: relative;
  display: inline-block;
  width: auto;
  max-width: 75%;
  color: rgb(0, 0, 0);
  background-color: rgb(242, 242, 242);
}
.rcw-message-text p {
  margin: 0;
}
.rcw-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}
.rcw-snippet {
  background-color: #f4f7f9;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}
.rcw-snippet-title {
  margin: 0;
}
.rcw-snippet-details {
  border-left: 2px solid #35e65d;
  margin-top: 5px;
  padding-left: 10px;
}
.rcw-link {
  font-size: 12px;
}
.quick-button {
  background: none;
  border-radius: 15px;
  border: 2px solid #1B9CFC;
  font-weight: 700;
  padding: 5px 10px;
  cursor: pointer;
  outline: 0;
}
.quick-button:active {
  background: #1B9CFC;
  color: #fff;
}
.loader {
  margin: 10px;
  display: none;
}
.loader.active {
  display: flex;
}
.loader-container {
  background-color: #f4f7f9;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}
.loader-dots {
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: gray;
  margin-right: 2px;
  animation: a 0.5s ease infinite alternate;
}
.loader-dots:first-child {
  animation-delay: 0.2s;
}
.loader-dots:nth-child(2) {
  animation-delay: 0.3s;
}
.loader-dots:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes a {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(5px);
  }
}
.rcw-messages-container {
  background-color: #fff;
  height: 50vh;
  max-height: 410px;
  overflow-y: scroll;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
  padding: 24px 24px 0px;
}
.rcw-full-screen .rcw-messages-container {
  height: 100%;
  max-height: none;
}
@media screen and (max-width: 800px) {
  .rcw-messages-container {
    height: 100%;
    max-height: none;
  }
}
.rcw-sender {
  align-items: center;
  display: flex;
  /* background-color: #f4f7f9; */
  height: 45px;
  padding: 5px;
  border-radius: 0 0 10px 10px;
  min-height: 56px;
  max-height: 200px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
.rcw-new-message {
  /* width: 100%;
  border: 0;
  background-color: #f4f7f9;
  height: 30px;
  padding-left: 15px; */
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
    /* padding: 18px 100px 18px 29px; */
    padding: 20px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.33;
    background-color: rgb(255, 255, 255);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    max-height: 200px;
    overflow: hidden;
    border: 0;
}
.rcw-new-message:focus {
  outline: none;
}
.rcw-send {
  /* background: #f4f7f9; */
  border: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 200ms ease 0s;
  height: 51px;
  margin-top: 2px;
  padding: 0px 16px;
  background: #fff;
}
.rcw-send:hover {
  opacity: 1;
}
.rcw-send .rcw-send-icon {
  /* height: 25px; */
  display: flex;
  align-items: center;
  width: auto;
  min-width: 16px;
  height: 16px;
}
@media screen and (max-width: 800px) {
  .rcw-sender {
    border-radius: 0;
    flex-shrink: 0;
  }
}
.quick-buttons-container {
  background: #fff;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
}
.quick-buttons-container .quick-buttons {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
.quick-buttons-container .quick-buttons .quick-list-button {
  display: inline-block;
  margin-right: 10px;
}
@media screen and (max-width: 800px) {
  .quick-buttons-container {
    padding-bottom: 15px;
  }
}
.rcw-conversation-container {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: d;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: d;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: d;
  animation-fill-mode: forwards;
  border-radius: 10px;
  box-shadow: 0 2px 10px 1px #b5b5b5;
}
.rcw-slide-out {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: e;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: e;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: e;
  animation-fill-mode: forwards;
}
.rcw-full-screen .rcw-conversation-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media screen and (max-width: 800px) {
  .rcw-conversation-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.rcw-launcher .rcw-badge {
  position: fixed;
  top: -10px;
  right: -5px;
  background-color: red;
  color: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
}
.rcw-launcher {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: d;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: d;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: d;
  animation-fill-mode: forwards;
  align-self: flex-end;
  background-color: #000;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  height: 60px;
  margin-top: 10px;
  width: 60px;
}
.rcw-launcher:hover {
  box-shadow: 0 8px 32px rgba(0, 0, 0, .4) !important;
}
.rcw-launcher:focus {
  outline: none;
}
.rcw-open-launcher {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: c;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: c;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: c;
  animation-fill-mode: forwards;
  height: 20px;
    margin: 14px 8px 14px 16px;
    width: 20px;
}
.rcw-open-launcher-text {
  align-items: center;
  color: #fff;
  display: flex;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  padding-right: 20px;
  width: auto !important;
}
.rcw-close-launcher {
  width: 20px;
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: b;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: b;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: b;
  animation-fill-mode: forwards;
}
@media screen and (max-width: 800px) {
  .rcw-launcher {
    bottom: 0;
    margin: 20px;
    position: fixed;
    right: 0;
  }
  .rcw-hide-sm {
    display: none;
  }
}
@keyframes b {
  0% {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes c {
  0% {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes d {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes e {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}
.rcw-widget-container {
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 0;
  max-width: 370px;
  position: fixed;
  right: 0;
  z-index: 1;
}
.rcw-opened {
  width: 90vw;
}
.rcw-full-screen {
  margin: 0;
  max-width: none;
  width: 100%;
}
.rcw-full-screen.rcw-opened {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 800px) {
  .rcw-widget-container {
    margin: 0;
    max-width: none;
    width: 100%;
  }
  .rcw-widget-container.rcw-opened {
    height: 100%;
  }
}


/* styles.css */
.iframe-container iframe {
  position: fixed; /* Use 'absolute' if the parent container is positioned */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none; /* Remove the border */
}

::-webkit-scrollbar {
  width: 8px; /* For vertical scrollbars */
  height: 8px; /* For horizontal scrollbars */
}

::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.5);
  border-radius: 4px;
}
.app_left-message__VcSBq {
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
  background-color: #f4f7f9;
}
.chat_rcw-conversation-container__2PLH- .chat_rcw-header__btMV0 {
  background-color: red;
}
.Image_image__pSe5r {
  max-width: 100%;
}
.msg-doc_relative{
  position: relative;
}
.msg-doc_loader{
  min-width: 100px;
  min-height: 60px;
}
.msg-doc_main{
  position: relative;
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding:9px 10px;
  background-color: rgba(191, 191, 191, 0.2);
  border-radius: 10px;
}
.msg-doc_content{
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 400px;
}
.msg-doc_name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 550;
  font-family: Nunito;
  font-size: 14px;
  line-height: 24px; 
}
.msg-doc_pointer{
  cursor: pointer;
}
.msg-doc_color{
  color: black;
  font-weight: 500;
}
.msg-doc_size{
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: #8696A0;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; 
}
.msg-doc_download_button{
  inset: none;
}
.msg-doc-symbol_size{
  font-size: 30px;
}
@media screen and (max-width:900px) {
  .msg-doc_content{
      width: 60%;
  }
}

.MultiChoice_container__1i5r5 {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.MultiChoice_options__YWg8Y {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4%;
  gap: 4%;
}

.MultiChoice_options__YWg8Y>button {
  flex: 36% 1;
  /* border: 1px solid black;
  box-shadow: 3px 2px 9px -2px #9b9a9a;
  margin-bottom: 10px;
  background: #f9fdff;
  border-radius: 3px;
  padding: 1%; */
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.MultiChoice_options__YWg8Y>button:disabled {
  cursor: not-allowed;
}
.Typing_tiblock__2MAsX {
  align-items: center;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  height: 17px;
}

.Typing_ticontainer__uebkW {
  border-radius: 10px;
  padding: 12px;
  max-width: 215px;
  background: #f4f7f9;
  .Typing_tidot__aJikJ {
    background-color: #90949c;
  }
}

.Typing_tidot__aJikJ {
  animation: Typing_mercuryTypingAnimation__1lP6Y 1.5s infinite ease-in-out;
  border-radius: 25px;
  display: inline-block;
  height: 7px;
  margin-right: 2px;
  width: 7px;
}

@keyframes Typing_mercuryTypingAnimation__1lP6Y {
  0% {
    transform: translateY(0px);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0px);
  }
}

.Typing_tidot__aJikJ:nth-child(1) {
  animation-delay: 200ms;
}
.Typing_tidot__aJikJ:nth-child(2) {
  animation-delay: 300ms;
}
.Typing_tidot__aJikJ:nth-child(3) {
  animation-delay: 400ms;
}

