.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
}

.options>button {
  flex: 36% 1;
  /* border: 1px solid black;
  box-shadow: 3px 2px 9px -2px #9b9a9a;
  margin-bottom: 10px;
  background: #f9fdff;
  border-radius: 3px;
  padding: 1%; */
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.options>button:disabled {
  cursor: not-allowed;
}