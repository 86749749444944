.tiblock {
  align-items: center;
  display: flex;
  gap: 4px;
  height: 17px;
}

.ticontainer {
  border-radius: 10px;
  padding: 12px;
  max-width: 215px;
  background: #f4f7f9;
  .tidot {
    background-color: #90949c;
  }
}

.tidot {
  animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 25px;
  display: inline-block;
  height: 7px;
  margin-right: 2px;
  width: 7px;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0px);
  }
}

.tidot:nth-child(1) {
  animation-delay: 200ms;
}
.tidot:nth-child(2) {
  animation-delay: 300ms;
}
.tidot:nth-child(3) {
  animation-delay: 400ms;
}
